ion-breadcrumb::part(native) {
	margin-left: 10px;
	padding-inline: unset;
}

ion-breadcrumb {
	color: var(--custom-color-text);
}

ion-breadcrumb.breadcrumb-active {
	color: var(--custom-color-tertiary)
}

ion-breadcrumb::part(separator) {
	color: transparent;
	user-select: none;
	width: 20px;
	background: url("/assets/images/down_arrow.svg") no-repeat center;
}
