
ion-button[expand="block"] {
	width: 75%;
	max-width: 350px;
	margin: 16px auto 0 auto;
}

ion-button {
	--border-radius: 60px;
	text-transform: capitalize;
}

ion-item ion-button[slot=start] {
	--border-radius: none;
	--background: none;
	border: none!important;
	background: none!important;
}

