ion-input.sc-ion-input-md-h {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 10px !important;

	width: 347px;
	height: 35px;

	background: rgba(255, 255, 255, 0.06) !important;
	border-radius: 3px;
}

ion-input.sc-ion-input-md-h[type='file'] {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 10px !important;

	width: 347px;
	height: 50px;

	background: rgba(255, 255, 255, 0.06) !important;
	border-radius: 3px;
}