@import "ion-button.scss";
@import "ion-toolbar.scss";
@import "ion-item.scss";
@import "ion-title.scss";
@import "ion-select";
@import "ion-input";
@import "ion-breadcrumb";
@import "ion-split-pane";

.component-header {
	width: 800px;
	display: flex;
	justify-content: space-between;

	ion-button {
		--background: var(--custom-color-dark-button);
	}

	ion-icon {
		height: 15px;
		margin-left: 10px;
	}
}

.page-title {
	font-family: 'Museo Sans', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	color: var(--custom-color-tertiary);
}

.form {
	margin-top: 32px;
	margin-bottom: 32px;

	ion-input,
	ion-select {
		margin-top: 10px;
		height: 35px;
	}
}

.items-list {
	padding: 12px 0 32px 0;
}


.buttons {
	ion-button {
		margin-right: 30px;
	}
}

.btn {
	&-save {
		--background: var(--custom-color-tertiary-button);
	}

	&-delete {
		--background: var(--custom-color-red-button);
	}

	&-create {
		--background: var(--custom-color-tertiary-button);
	}

	&-cancel {
		--background: var(--custom-color-dark-button);
	}
}

.form-container {
	display: flex;
	width: 800px;
	justify-content: space-between;
}


.toasty {
	display: flex;
	text-align: center;
}

.ion-content-padding {
	--margin-bottom: 60px;
	--padding-bottom: 32px;
	--padding-end: 32px;
	--padding-start: 32px;
	--padding-top: 32px;
}