ion-item {
    ion-button[slot="start"] {
        margin-right: 4px;
		background: #1ea7fd;
    }
}
.item-interactive {
	--border-width: 0!important;
	--show-full-highlight: 0!important;
}

ion-item::part(native) {
	--background-hover: none;
}

.ion-item-edit {
	width: 800px;
	font-family: "Museo Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;

	margin-bottom: 10px;
	ion-button {
		--ion-color-base: var(--custom-color-text) !important;
		text-transform: capitalize;
	}
}
