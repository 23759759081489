.disabled-select-check-box {

	.sc-ion-alert-md {
		text-align: center!important;
		margin-right: 20px;
	}

	.alert-radio-icon {
		background: red!important;
		display: none !important;
	}
}

ion-select {
	margin-left: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 6px 10px;
	gap: 10px;

	width: 347px;
	height: 30px;

	background: rgba(255, 255, 255, 0.06);
	border-radius: 3px;
}

ion-select::part(icon) {
	content: url("/assets/images/down_arrow.svg");
	box-sizing: content-box;
	height: 13px;
	border: 5px;
	transform: rotate(90deg);
	opacity: 1;

}

.item-has-focus {
	--border-color: #1ea7fd;
	::part(icon) {
		transform: rotate(270deg);
	}
}
